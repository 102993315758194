// Here you can add other styles

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: $color_gray;
    line-height: 16px;
    background-color: transparent;
}

a {
    color: $color_primary;
}
.sidebar {
    background: #fff;
    .nav-link {
        color: #3a4248;
        &.active, &:hover {
            background: transparent;
            color: #14299f;
            font-weight: 600;
        }
    }
    .nav-dropdown.open {
        background: #fff;
        .nav-link {
            color: #3a4248;
            &.active, &:hover {
                background: transparent;
                color: #14299f;
                font-weight: 600;
            }
        }
    
    }
}
.react-bs-table .table-bordered>tbody>tr>td {
    font-size: 0.8rem;
}
.react-bs-table .table-bordered>thead>tr>th {
    font-size: 0.9rem;
}

.header-nav {
    font-size: 1.3rem;
    font-weight: bold;
}
.nav-tabs .nav-item {
    margin-bottom: 0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs, .tab-content, .nav-link {
    border: 0;
    padding-left: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link{
    border: none;
}

.nav-tabs {
    border-bottom: 3px solid #E6E6E6;
}

.nav-tabs .nav-link  {
    // color: $color_primary;
    font-weight: 500;
    font-size: 1rem;
}

.nav-tabs .nav-link.active {
    color: $color_primary !important;
}

.nav-tabs .nav-link:hover {
    padding-left: 0;
}

.main .container-fluid {
    padding: 0;
}

.form-group {
    label {
        display: inline-block;
        margin-bottom: 0.2rem;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 500;
    }
}

.breadcrumb {
    margin-bottom: 15px;
    font-size: 0.8rem;
    padding: 7px 15px;
}

.card-body {
    padding: 15px;
}

.card {
    border: none;
    .card-header {
        border: none;

        h2 {
            position: absolute;
            left: 65px;
            top: 15px;
        }

        p {
            font-size: 0.8rem;
            font-weight: 400;
            padding-top: 15px;
            margin-bottom: 0;
        }

        .link-edit {
            cursor: pointer;
            color: $color_primary;
        }
        
    }

    .card-header-actions {
        line-height: 1.5;
    }

    .card-body {
        padding: 15px;
        box-shadow: 1px 3px 12px 4px #cccccc2e;
        margin: 15px 0;
    }
}

.btn-samsung {
    color: #fff;
    background-color: $color_primary;
    border-color: $color_primary;

    &:hover {
        color: #fff;
    }
}

.btn-outline-samsung {
    color: $color_primary;
    background-color: #fff;
    border-color: $color_primary;

    &:hover {
        background-color: $color_primary;
        color: #fff;
    }

}

.branch-section-wrapper {
    // padding-top: 20px;
    .section-header {
        position: relative;
        h3 {
            font-size: 1.3rem;
            position: relative;
            line-height: 2;
            color: $color_primary;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 100%;
                height: 2px;
                background-color: #E6E6E6;
                
            }
        }
    }

    .btn-upload-wrapper {
        position: absolute;
        top: 5px;
        right: 0;
        border: 1px solid $color_primary;
        padding: 7px 20px;
        border-radius: 0.25rem;
        color: $color_primary;
        cursor: pointer;
    }
}


.modal-style {
    h3 {
        color: #999999;
        font-size: 1.4rem;
    }
    p {
        color: #999999;
    }
}

.page-item.active .page-link {
    background-color: $color_primary;
    border-color: $color_primary;
}

.page-link, .page-link:hover{
    color: $color_primary;
}

.page-link:focus {
    box-shadow: none;
}

.curser-pointer {
    cursor: pointer;
}

.import-file-wrapper {
    padding: 15px;
    border: 1px solid #f3f2f2;
    margin-bottom: 25px;
}

input[type="file"]::-webkit-file-upload-button{
    background-color: #f6f6f6;
    border-radius: 4px;
    &:focus {
        outline: none;
    }
 }

 .modal-dialog.loading {
    .modal-content {
        background-color: transparent;
        border: none;
        text-align: center;
    }
 }

 .sidebar .nav-dropdown-items {
     padding-left: 15px;
 }

 .form-control {
     color: #4D4D4D;
 }

 .app-body {
     .main {
         padding-right: 15px;
     }
 }


 .list-p-wrapper {
    .table {
        >tbody > tr > td {
            padding: 0;
            vertical-align: middle;
        }

        &.sub-table {
            thead {
                tr {
                    td {
                        border-top: none;
                    }
                }
            }
        }
    }
 }